<template>
    <b-row class="px-2 mb-2">
        <b-col sm="12">
            <b-row>
                <b-col sm="4" class="border border-light border-1 p-1">
                    <b-row class="d-flex align-items-center">
                        <b-col sm="12" xl="4" class="px-0 mr-2">
                            <div class="text-center">
                                <img v-img-orientation-changer src="img/ayun-qb.png" class="img-logo" alt="Ayun! Logo" />
                            </div>
                        </b-col>
                        <b-col sm="12" xl="7" class="pl-2 pr-0 text-sm-center text-xl-left">
                            <div class="dispatch-header">AYUN! DOCKET</div>
                            <div class="dispatch-id"> {{ getDispatchNo() }} </div>
                            <div class="dispatch-date"> {{ showFormattedDate(selDispatch.dateCreated) }} </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="4" class="border border-light border-1 p-4">
                    <div class="transportation-field">Plate No./Conduction No.: <span class="transportation-value-uppercase">{{
                        selDispatch.transportation
                        ? selDispatch.transportation.plateNo
                        : '-'
                    }}</span></div>
                    <div class="transportation-field">Truck Company: <span class="transportation-value-uppercase">{{
                        selDispatch.transportation
                        ? selDispatch.transportation.company
                        : '-'
                    }}</span></div>
                    <div class="transportation-field">Driver: <span class="transportation-value-uppercase">{{
                        selDispatch.driver
                        ? selDispatch.driver.name
                        : '-'
                    }}</span></div>
                    <div class="transportation-field">Assistant(s): <span class="transportation-value-uppercase">{{
                        getAssistants()
                    }}</span></div>
                </b-col>
                <b-col sm="2" class="border border-light border-1 p-4">
                    <qr-code class="d-flex justify-content-center" :size="90" :text="getDispatchId()" />
                    <div class="scan-to-view text-center mt-2">Scan In Ayun! App</div>
                </b-col>
                <b-col sm="2" class="border border-light border-1 pt-4">
                    <div class="total text-center mb-4">Total</div>
                    <div class="total-asset-type text-center">
                        <span :class="hasDiscrepancy() ? 'has-discrepancy' : ''">
                            {{ getActualAssets() }}/{{ getExpectedAssets() }}
                        </span>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

export default {
    name: 'dispatch-summary-header',
    props: {
        selDispatch: {
            type: Object,
            required: true,
        }
    },
    methods: {
        showFormattedDate(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getExpectedAssets() {
            let total = 0;
            if (this.selDispatch.assets) {
                for (const asset of this.selDispatch.assets)
                    total += parseInt(asset.expectedQuantity);
            }
            return total;
        },
        getActualAssets() {
            let total = 0;
            if (this.selDispatch.assets) {
                for (const asset of this.selDispatch.assets)
                    total += parseInt(asset.actualQuantity);
            }
            return total;
        },
        hasDiscrepancy() {
            return this.selDispatch.status === 'Received' && this.getActualAssets() !== this.getExpectedAssets();
        },
        getDispatchId() {
            let dispatch = this.selDispatch ? this.selDispatch : {};
            return dispatch.dispatchId ? dispatch.dispatchId : "-";
        },
        getDispatchNo() {
            let dispatch = this.selDispatch ? this.selDispatch : {};
            return dispatch.dispatchNo ? dispatch.dispatchNo : "-";
        },
        getAssistants() {
            let dispatch = this.selDispatch ? this.selDispatch : {};
            let driver = dispatch && dispatch.driver ? dispatch.driver : {};
            return driver.assistants ? driver.assistants : '-';
        }
    }
}
</script>

<style scoped>
.img-logo {
    margin-top: 5wh;
    margin-left: 10wh;
    width: 120wh;
}

.dispatch-header {
    text-transform: uppercase;
    color: #F18F01;
    font-weight: bold;
    font-size: 1.9em;
    line-height: normal;
    margin: 0 auto;
    margin-top: -6px;
}

.dispatch-id {
    text-transform: uppercase;
    color: #122C91;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dispatch-date {
    color: #484554;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.5em;
    align-items: center;
}

.transportation-field {
    font-size: medium;
    text-align: left;
    text-transform: uppercase;
    color: #4A4A4A;
    font-weight: 400;
    line-height: normal;
}

.transportation-value-uppercase {
    font-size: medium;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: #484554;
    font-weight: 700;
    line-height: normal;
}

.scan-to-view {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7em;
    color: #122C91;
    vertical-align: bottom;
    text-align: center;
}

.total {
    text-transform: uppercase;
    color: #122C91;
    font-size: 1.5em;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.total-asset-type {
    text-transform: uppercase;
    color: #4A4A4A;
    font-size: 1.5em;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.has-discrepancy {
    color: #f44335;
}
</style>
<template>
    <b-row class="my-4">
        <b-col class="footer-col fixed-footer text-right" sm="12">
            <span class="copy-owner" v-if="copyOwner.length > 0">{{ copyOwner + ' Copy' }}</span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'dispatch-summary-footer',
    props: {
        copyOwner: {
            type: String,
            required: true
        }
    },
}
</script>

<style scoped>
.footer-col {
    padding-top: 0.7em;
    font-size: small;
}

.copy-owner {
    text-transform: uppercase;
}

.fixed-footer {
    position: absolute;
    /* Allows positioning within the parent container */
    bottom: 0.5em;
    /* Distance from the bottom edge */
    right: 0.7em;
    padding: 0.7em;
}
</style>
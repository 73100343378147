import { DateUtil } from '@/utils/dateutil';
import axios from 'axios';
import config from '@/config/env-constants';


export default {
	baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

	getDispatches(filterBy, view, currUserId) {
		const url = `${this.baseUrl}/getDispatches`;
		return axios.post(url, {
			view: view,
			currUserId: currUserId,
			filterBy: JSON.stringify(filterBy)
		});
	},

	saveDispatch(dispatch, currUserId) {
		let url = `${this.baseUrl}/saveDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			dispatch: JSON.stringify(dispatch)
		});
	},

	deployDispatch(dispatch, currUserId) {
		let url = `${this.baseUrl}/deployDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			dispatch: JSON.stringify(dispatch)
		});
	},

	cancelDispatch(dispatch, currUserId) {
		let url = `${this.baseUrl}/cancelDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			dispatch: JSON.stringify(dispatch)
		});
	},

	receiveDispatch(dispatch, currUserId, currTimeStamp) {
		let url = `${this.baseUrl}/receiveDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: currTimeStamp,
			dispatch: JSON.stringify(dispatch)
		});
	},

	rejectDispatch(dispatch, currUserId, currTimeStamp) {
		let url = `${this.baseUrl}/rejectDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: currTimeStamp,
			dispatch: JSON.stringify(dispatch)
		});
	},

	saveClassification(classification, currUserId, currTimeStamp) {
		let url = `${this.baseUrl}/saveClassification`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: currTimeStamp,
			classification: JSON.stringify(classification)
		});
	}
}
